import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./BlogDetail.css";
import Blog1 from "./utils/images/blog1.jpg";
import Blog2 from "./utils/images/blog2.jpeg";

const BlogDetail = () => {
  const { id } = useParams();

  const blogs = {
    "1": {
      title: "What to Expect for Immigrants and Non-immigrants Under a Trump Administration",
      author: "VIPUL JOSHI & RIDA FATEMA MOLEDINA(Intern)",
      content: (
        <>
          <p>
          As Donald Trump campaigns for a second term in the 2024 U.S. presidential election, immigrants, both documented and undocumented, are closely watching his promises to reshape immigration policy. 
          </p>
          <p>
          Should Trump return to office, several key policy changes affecting immigrant communities ranging from the elimination of Deferred Action for Childhood Arrivals (DACA) to potential restrictions on humanitarian protections could have profound implications for millions of people in the U.S. 
          </p>
          <p>
          This article explores the expected policy shifts that could significantly affect immigrants and the broader social landscape.
          </p>

          <h2>1. H-1B/H-4 Visa Changes: Will it affect Indian professionals?</h2>
          <p>
          Trump’s win could mean stricter H-1B regulations, as he has previously pushed to prioritise American workers. 
          A stricter policy could increase competition, impacting Indian tech professionals who depend on H-1B renewals and applications. 
          </p>
          <p>
          Trump's emphasis on merit-based immigration could benefit skilled Indian workers but warned of potential new restrictions on entry requirements.  
          </p>
          <p>
          Another potential area of change is the H-1B ballot system. Trump has previously advocated for replacing the lottery system with a merit-based selection process, which would favour candidates with advanced skills and higher wages.  
          </p>

          <p>
          H-4 EAD program was introduced in 2015 under the Obama administration. It allowed spouses of certain H-1B visa holders to apply for work authorization in the U.S. The goal was to provide greater economic opportunities for the families of highly skilled foreign workers, especially in the STEM fields.
          </p>
          <p>
          One of the central goals of President Trump's "America First" immigration policies was to restrict immigration and prioritize U.S. workers. In 2017, the Trump administration took steps to rescind the H-4 EAD rule, claiming that the program created competition for U.S. workers and harmed American jobs. The proposed termination of the program was part of a broader push to limit visa-related benefits for foreign nationals.
            H-4 EAD maybe a thing of past.
           </p>
          



          <h2>2. Student Visas and Green Card Pathways</h2>
          <p>
          The Trump administration may impose tighter controls on student visas, particularly the F-1 visa, as well as restrictions on the Optional Practical Training (OPT) program, which allows international students to work in the U.S. after graduation. Although tighter visa controls may create challenges, there is potential for policies that could facilitate Green Card pathways for skilled foreign graduates, offering opportunities for high-achieving students from countries like India.
          </p>

          <h2>3. Birthright Citizenship:</h2>
          <p>
          Trump has consistently expressed his intention to end birthright citizenship for children born in the U.S. to non-citizen parents, despite the constitutional protections provided by the 14th Amendment. If enacted, this policy could significantly impact immigrant families, leaving U.S.-born children without citizenship and complicating access to essential services such as healthcare, education, and social security.
          </p>

          <h2>4.Public Charge Rule Adjustments:</h2>
          <p>
          One of the most significant shifts under a Trump administration could be the reinstatement of the 2019 public charge rule. This policy expanded the criteria to assess whether immigrants are likely to become dependent on government assistance programs like Medicaid, food assistance, and housing benefits, potentially making it more difficult for immigrants to obtain permanent residency. Immigrant families could be deterred from seeking essential services due to concerns that it may jeopardize their immigration status.
          </p>

          <h2>5.Restrictions on Humanitarian Protections:</h2>
          <p>
          Trump’s administration is likely to impose stricter limitations on humanitarian protections, including refugee admissions and asylum policies. Further reductions in refugee quotas and a more stringent approach to Temporary Protected Status (TPS) could leave many individuals from countries experiencing conflict or natural disasters vulnerable to deportation.
          </p>

          <h2>6.Potential Elimination of DACA program:</h2>
          <p>
          The Deferred Action for Childhood Arrivals (DACA) program, which protects undocumented immigrants who arrived in the U.S. as children, remains at risk under a Trump administration. Although the U.S. Supreme Court blocked Trump’s efforts to end the program during his first term, a second term could see renewed attempts to dismantle DACA, leaving over half a million recipients at risk of deportation and losing their work authorization.
          </p>


          <h2>Conclusion:</h2>
          <p>
          These anticipated policy changes are part of Trump’s broader agenda to reshape immigration in the U.S. His hardline stance on immigration could exacerbate the existing challenges faced by immigrant communities, including increased deportations, restricted access to public services, and heightened social tensions. For non-immigrants, particularly those in industries reliant on immigrant labour, such policies could exacerbate worker shortages and disrupt key sectors of the economy, including healthcare and agriculture.
          A second term under Donald Trump would likely see significant shifts in U.S. immigration policy, with deep implications for immigrant families and the broader economy. Whether it’s the potential elimination of DACA, changes to public charge policies, or tighter restrictions on humanitarian protections, immigrants in the U.S. are likely to face greater uncertainty and fear. While these policy changes would benefit some segments of the population, they could also lead to increased hardship for vulnerable communities, further fuelling debates about immigration in America.
          </p>
        </>
      ),
      image: Blog1,
    },
    "2": {
      title: "Overview of the Jury System in the USA",
      author: "VIPUL JOSHI & RIDA FATEMA MOLEDINA(Intern)",
      content: (
        <>
          <p>
          The jury system is a cornerstone of the American justice system, representing a fundamental principle of democracy: the right of individuals to be judged by a group of their peers. This system plays a critical role in ensuring fairness and impartiality in legal proceedings, especially in criminal and civil cases. 
          </p>
          <h2>What is the Jury System?</h2>
          <p>
          The American Jury is not a United States invention. It is rooted in a process that began in medieval England, or earlier, and had parallels in other countries on the continent. Although the Jury began in England, it is fair to say that it reached its fullest flowering and its traditional form and responsibilities has endured the longest in the United States. 
          The jury system is a method of determining the outcome of a legal case in which a group of citizens is selected to review evidence, hear testimony, and make a decision on the guilt or innocence of a defendant, or on the liability in a civil case. Jurors are tasked with deliberating the facts of a case and applying the law as instructed by the judge.
          In the United States, jury trials are required in both criminal and civil cases, although the specifics of when a jury is used can vary based on the type of case and jurisdiction. The U.S. Constitution guarantees the right to a jury trial under the Sixth and Seventh Amendments, with criminal defendants guaranteed a jury in all serious offenses, and civil litigants assured a jury when the amount in controversy exceeds a certain threshold (usually $20).
          </p>

          <h2>Types of Juries:</h2>
          <h3>There are two main types of juries in the United States legal system:</h3>
          <ul>
            <li>
              <strong>Criminal Juries:</strong> In criminal cases, a jury typically consists of 12 individuals who are tasked with determining the guilt or innocence of a defendant. The standard of proof is "beyond a reasonable doubt," meaning that the prosecution must prove the defendant’s guilt to such an extent that no reasonable doubt exists in the minds of the jurors.
            </li>
            <li>
              <strong>Civil Juries:</strong> In civil cases, the jury may be smaller, usually consisting of 6 to 12 members, and is tasked with determining whether the defendant is liable for damages. In these cases, the burden of proof is "preponderance of the evidence," meaning that the plaintiff must prove that it is more likely than not that the defendant is liable.
            </li>
          </ul>
          <h2>Grand Jury vs. Trial Jury</h2>
          <h3>What's the Difference?</h3>
          <p>
          Grand Jury and Trial Jury are two distinct entities within the legal system. The Grand Jury is responsible for determining whether there is enough evidence to bring criminal charges against an individual. It consists of a group of citizens who review evidence presented by the prosecution and decide if there is probable cause to proceed with a trial. 
          On the other hand, the Trial Jury, also known as the Petit Jury, is responsible for determining the guilt or innocence of the defendant during a trial. It is composed of a group of citizens who listen to the evidence presented by both the prosecution and defence and reach a verdict based on the facts presented. While the Grand Jury operates in secrecy and its proceedings are not open to the public, the Trial Jury operates in an open court and its deliberations are subject to public scrutiny.
          </p>

          <h2>Jury Selection Process:</h2>
          <p>
          The process of selecting jurors, known as "voir dire," is a critical step in ensuring that a trial is fair. Potential jurors are randomly selected from a pool of eligible citizens (usually registered voters or individuals with a driver's license), and they are then questioned by both the prosecution and defence attorneys. The goal of this questioning is to identify any potential biases or conflicts of interest that could affect a juror’s ability to be impartial.
         Attorneys may use "challenges for cause" to dismiss jurors who are deemed biased or unsuitable, or "peremptory challenges" to dismiss jurors without providing a reason (although peremptory challenges cannot be used to discriminate based on race, gender, or ethnicity). The result is a jury composed of individuals who are thought to be fair and unbiased, and who will decide the case based solely on the evidence presented in court.
          </p>

          <h2>The Role of the Jury in a Trial:</h2>
          <p>
          Once the jury is selected and the trial begins, the jurors' primary role is to listen to the evidence presented by both sides, including testimony from witnesses, physical evidence, and expert opinions. They must remain impartial and avoid any external influences, such as media coverage or personal biases, that might affect their decision.
          During the trial, the judge provides legal guidance, instructing the jury on the relevant law and how it should be applied to the facts of the case. This ensures that jurors do not make decisions based on emotions, but on the facts and the law.
          At the conclusion of the trial, the jury deliberates in private to reach a verdict. In criminal cases, a unanimous decision is typically required to convict the defendant, although in some jurisdictions, a non-unanimous verdict may be acceptable. In civil cases, a majority verdict is often sufficient. 
          </p>

          <h2>The Importance of the Jury System:</h2>
          <h3>The jury system serves several key purposes in the U.S. legal system:</h3>
          <ul>
            <li>
              <strong>1. Checks and Balances:</strong>The jury serves as a check on government power by ensuring that a group of ordinary citizens, rather than just legal professionals or judges, has the final say in criminal and civil cases. This helps to prevent potential abuses of power by the state and ensures a system of justice that is accountable to the public.
            </li>
            <li>
              <strong>2. Impartiality and Fairness:</strong> Juries are intended to be a cross-section of society, ensuring that individuals from various backgrounds, experiences, and perspectives contribute to the decision-making process. This helps to minimize biases and ensure that verdicts are based on the facts, rather than on the status, wealth, or influence of the parties involved.
            </li>
            <li>
              <strong>3. Transparency:</strong> Jury trials are typically public, and the decision-making process involves open deliberation and reasoning. This transparency fosters confidence in the justice system and allows the public to witness the judicial process in action.
            </li>
            <li>
              <strong>4. Democratic Participation:</strong> Serving as a juror is one of the most direct ways that citizens can participate in the democratic process. It allows individuals to contribute to the legal system and helps to ensure that justice is carried out in a way that reflects societal values.
            </li>
          </ul>

          <h2>Conclusion:</h2>
          <p>
          The jury system is a foundational aspect of the U.S. legal system, embodying the principles of fairness, democracy, and impartiality. It ensures that justice is not only done, but seen to be done, by a cross-section of society. While it is not a perfect system and comes with its challenges, the jury remains an essential safeguard against injustice, upholding the rights of individuals and ensuring that legal decisions reflect the will of the people. 
          In 1959, Naval Commander N. K. Nanavati shot and killed his wife’s lover. He then turned himself into the local police and was put on trial for murder. Although many states had moved already to severely limit or eliminate jury trials, the Bombay Sessions Court administered one of the few jurisdictions where juries were still employed in serious criminal cases. After hearing the case, the jury there voted overwhelmingly, 8-1, to acquit Nanavati. The presiding judge declared the verdict ‘perverse’ and the case was sent up to the High Court where the verdict was overturned. The jury’s failure to convict Nanavati, it is often argued, led directly to the abolition of jury trials throughout the country.
          Whether one views it as a civic duty or a complex and challenging responsibility, serving on a jury is a powerful reminder of the role that ordinary citizens play in maintaining the integrity of the justice system.
          </p>
        </>
      ),
      image: Blog2,
    },
  };

  const blog = blogs[id];

  return (
    <>
        {/* Helmet for Meta Information */}
        <Helmet>
            <title>{`${blog.title} | Vipul Joshi & Associates - Immigration Law Blog`}</title>
            <meta name="description" content={blog.description} />
            <meta
            name="keywords"
            content={`Immigration law, ${blog.title}, Vipul Joshi & Associates, U.S. immigration policies, legal blog`}
            />
        </Helmet>
        
        <div className="blog-detail">
            <img src={blog.image} alt={blog.title} className="blog-detail-image" />
            <h1>{blog.title}</h1>
            <h3>~ {blog.author}</h3>
            <p>{blog.content}</p>
        </div>
    </>
  );
};

export default BlogDetail;
