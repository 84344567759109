import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import ContactInfo from './ContactDetails';
import Home from './Home'; // New Home component to wrap existing sections (discussed below)
import AboutUsPage from './AboutUsPage'; // Detailed About Us page
import CaseStudyPage from './CaseStudyPage';
import ContactUsPage from './ContactUsPage';
import AreasOfExpertise from './AreaOfExpertise';
import BlogPage from './BlogPage';
import BlogDetail from './BlogDetail';

function App() {
  return (
    <Router>
      <div className="App">
        <ContactInfo />
        <Navbar />
        <Routes>
          {/* Home Route */}
          <Route path="/" element={<Home />} />
          
          {/* About Us Route */}
          <Route path="/about-us" element={<AboutUsPage />} />
          {/* Case Study Page */}
          <Route path='/case-study' element = {<CaseStudyPage />}/>
          <Route path='/contact-us-page' element = {<ContactUsPage/>}/>
          <Route path='/areas-of-expertise' element = {<AreasOfExpertise/>}/>
          <Route path='/blog' element = {<BlogPage/>}/>
          <Route path="/blog/:id" element={<BlogDetail />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
