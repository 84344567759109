import React from 'react';
import './Footer.css'; // Import the CSS file
import logoImg from "./utils/images/logo2.jpg"

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-about">
            <img src={logoImg} alt="Logo" className="footer-logo" />
            <p>
              Vipul Joshi & Associates Immigration Lawyers provide expert legal guidance with a
              personalized touch. Specializing in U.S. and Canadian immigration law, we are committed
              to achieving your immigration goals.
            </p>
          </div>
          
          <div className="footer-links">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about-us">About Us</a></li>
              <li><a href="/case-study">Case Study</a></li>
              <li><a href="/areas-of-expertise">Areas Of Expertise</a></li>
              <li><a href='/blog'>Blogs</a></li>
              <li><a href='/contact-us-page'>Contact Us</a></li>
            </ul>
          </div>

          <div className="footer-contact">
            <h3>Contact Us</h3>
            <p>Vipul Joshi & Associates</p>
            <p>Office No. 118, 3rd Floor, Building No. 24/B, Raja Bahadur Mansion, Fort, Mumbai: 400001</p>
            <p><i className="fas fa-phone-alt"></i> +91-9920082168</p>
            <p><i className="fas fa-envelope"></i> joshivipul.law@gmail.com</p>
          </div>
        </div>
        
        <div className="footer-bottom">
          <p>&copy; 2024 Vipul Joshi & Associates. All rights reserved.</p>
          <div className="social-icons-footer">
            <a href="https://www.facebook.com/vipul.joshi.5201" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
            <a href="https://www.linkedin.com/in/vipuljoshiassociates/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
            <a href="https://www.instagram.com/vipul.joshi009?igsh=MWZtb2F6eGRiOTg4NQ%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
