import React, { useState, useEffect } from 'react';
import './Navbar.css'; // Import the CSS file
import logoImg from "./utils/images/logo2.jpg";
import { useLocation } from 'react-router-dom'; // Import useLocation from React Router

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // Get the current URL path

  const toggleMenu = (event) => {
    event.stopPropagation(); // Prevents the event from bubbling up to the document
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuOpen && !event.target.closest('.navbar-container')) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <header className="navbar">
      <div className="navbar-container">
        <div className="logo">
          <img src={logoImg} alt="Logo" />
        </div>
        <div className={`menu-icon ${menuOpen ? 'hidden' : ''}`} onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </div>
        <nav className={`nav-links ${menuOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
          <a href="/" className={location.pathname === '/' ? 'active' : ''}>Home</a>
          <a href="/about-us" className={location.pathname === '/about-us' ? 'active' : ''}>About Us</a>
          <a href="/case-study" className={location.pathname === '/case-study' ? 'active' : ''}>Case Study</a>
          <a href="/areas-of-expertise">Areas of Expertise</a>
          <a href="/blog">Blogs</a>
          {/* <a href="#practice-areas">Practice Areas</a>
          <a href="#blog">Blog</a> */}
        </nav>
        <a href="/contact-us-page" className="btn-consultation">Contact Us</a>
      </div>
    </header>
  );
};

export default Navbar;
