import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./BlogPage.css";
import Blog1 from "./utils/images/blog1.jpg";
import Blog2 from "./utils/images/blog2.jpeg";

const BlogPage = () => {
  const blogs = [
    {
      id: "1",
      title: "What to Expect for Immigrants and Non-immigrants Under a Trump Administration",
      author: "VIPUL JOSHI & RIDA FATEMA MOLEDINA(Intern) ",
      excerpt:
        "As Donald Trump campaigns for a second term in the 2024 U.S. presidential election, immigrants are closely watching his promises to reshape immigration policy... ",
      image: Blog1,
    },
    {
      id: "2",
      title: "Overview of the Jury System in the USA",
      author: "VIPUL JOSHI & RIDA FATEMA MOLEDINA(Intern)",
      excerpt:
        "The jury system is a cornerstone of the American justice system, representing a fundamental principle of democracy: the right of individuals to be judged by a group of their peers...",
      image: Blog2,
    },
  ];

  return (
        <>
        {/* Helmet for Meta Information */}
        <Helmet>
            <title>Blog | Vipul Joshi & Associates - Immigration Law Insights</title>
            <meta
            name="description"
            content="Stay updated with the latest immigration law insights, expert opinions, and success stories from Vipul Joshi & Associates."
            />
            <meta
            name="keywords"
            content="Immigration law, blog, legal insights, U.S. immigration, Visa updates, Vipul Joshi & Associates"
            />
        </Helmet>

        <div className="blog-page">
        <header className="blog-header">
            <h1>Insights & Stories</h1>
            <p>Stay updated with the latest legal insights and success stories.</p>
        </header>

        <section className="blog-section">
            {blogs.map((blog) => (
            <Link
                key={blog.id}
                to={`/blog/${blog.id}`}
                className="blog-card" // Link component wraps the card
            >
                <img src={blog.image} alt={blog.title} className="blog-image" />
                <h3>{blog.title}</h3>
                <p>{blog.excerpt}</p>
                <span>~ {blog.author}</span>
            </Link>
            ))}
        </section>
        </div>
    </>
  );
};

export default BlogPage;
